import { useRoutes } from 'react-router-dom';
import './App.css';
import React from 'react';
import routes from './routes';

const AppRoutes = ({path})=>{
  const element = useRoutes(path);
  return element; 
}
function App() {
    return (
       <AppRoutes path={routes}/>
  )
}

export default App;
