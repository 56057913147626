/* eslint-disable react/react-in-jsx-scope */

import loadable from "@loadable/component";
import { Loader } from "../components";

export const Login = loadable(() => import("./Login/index"), {
  fallback: <Loader />,
});
export const SignupForm = loadable(() => import("./Signup/index"), {
  fallback: <Loader />,
});
export const Dashboard = loadable(() => import("./Dashboard"), {
  fallback: <Loader />,
});
export const Profile = loadable(() => import("./Profile"), {});
export const Processing = loadable(() => import("./Processing"), {});
export const FileDownload = loadable(() => import("./FileDownload"), {});
