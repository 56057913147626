/* eslint-disable react/react-in-jsx-scope */
import { NoDataFound } from "../components";
import { AppLayout, AuthLayout } from "../Layouts";
import loginRoutes, { signupRoutes } from "./Auth/index.route";
import { dashboardRoute } from "./Dashboard/index.route";

const routes = [
  {
    path: "/",
    element: <AppLayout />,
    children: loginRoutes,
  },
  {
    path: "",
    element: <AuthLayout />,
    children: dashboardRoute,
  },
  {
    path: "*",
    element: <NoDataFound />,
  },
];
export default routes;
