/* eslint-disable react/react-in-jsx-scope */
import { Login,SignupForm } from "../../Pages";

const loginRoutes = [{
  path: "",
  element: <Login />,
},
{
  path: "/signup",
  element: <SignupForm />,
},
]



export default loginRoutes;
